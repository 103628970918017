<template>
  <div id="wrapper" style="background-color: white">
    <header-component></header-component>

    <!-- <div v-if="showRefinePopup" class="popup-overlay">
      <div class="popup-content">
        <button type="button" class="popup-close" @click="closeRefinePopup">
          <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="popup-title">Refine your office search</h3>

        <div class=" " style="margin-bottom: 20px">
          <div v-click-outside="hideSlider">
            <div class="row">
              <div class="col-md-12">
                <div class="search__field fieldFloating border-add">
                    <input
                        type="text"
                        max="500"
                        min="1"
                        placeholder=" "
                        :value="headCount"
                        readonly="true"
                        class="formControl validate_number"
                        @click="isSlider = true"
                      />
  
                      <label class="fieldFloating__label">Max Capacity</label>
                </div>
              </div>
            </div>
            <div class="search__field fieldFloating minmaxslider border-add">
              <vue-slider
                v-if="isSlider"
                v-model="headCount"
                v-bind="options"
                :max="500"
                :min="0"
                class="formControl"
              ></vue-slider>
            </div>
          </div>
          <div style="margin-top: 70px">
            <div class="fieldFloating border-add">
              <div class="d-flex">
                <datepicker
                  ref="picker"
                  @focusout.native="close"
                  class="formControl"
                  v-model="start_date"
                  @focusin.native="getStartDate"
                >
                </datepicker>

                <button v-if="start_date" class="date-reset" @click="removeDate()">
                  X
                </button>
              </div>
              <label
                :class="
                  start_date
                    ? 'fieldFloating__label date_selected'
                    : 'fieldFloating__label date_open'
                "
                >Start Date</label
              >
            </div>
          </div>
        </div>

        <center><button class="submit-refine" @click="refineHandler()">Submit</button></center>
      </div>
    </div> -->

    <div v-if="showRefinePopup" class="popup-overlay">
      <div class="popup-content">
        <button type="button" class="popup-close" @click="closeRefinePopup">
          <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="popup-title">Refine your office search</h3>

        <div class="" style="margin-bottom: 20px">
          <div>
            <div
              v-click-outside="hideSlider"
              class="row"
              style="margin: 0px -8px 0 -12px"
            >
              <div class="col-md-12">
                <!-- Max Capacity -->
                <div class="search__field fieldFloating border-add">
                  <input
                    type="text"
                    max="500"
                    min="1"
                    placeholder=" "
                    :value="headCount"
                    readonly="true"
                    class="formControl validate_number"
                    @click="isSlider = true"
                  />
                  <label class="fieldFloating__label">Occupancy Limit</label>
                </div>
              </div>
            </div>
            <div class="search__field fieldFloating minmaxslider border-add">
              <vue-slider
                v-if="isSlider"
                v-model="headCount"
                v-bind="options"
                :max="500"
                :min="0"
                class="formControl"
              ></vue-slider>
            </div>

            <!-- Start Date -->
            <div :style="{ marginTop: isSlider ? '15%' : '5%' }">
              <div class="fieldFloating border-add">
                <div class="d-flex">
                  <datepicker
                    ref="picker"
                    @focusout.native="close"
                    class="formControl"
                    v-model="start_date"
                    @focusin.native="getStartDate"
                  ></datepicker>
                  <button v-if="start_date" class="date-reset" @click="removeDate()">
                    X
                  </button>
                </div>
                <label
                  :class="
                    start_date
                      ? 'fieldFloating__label date_selected'
                      : 'fieldFloating__label date_open'
                  "
                  >Date for Lease Availability</label
                >
              </div>
            </div>
            <!-- Minimum Contract Length -->
            <div class="search__field fieldFloating border-add" style="margin-top: 5%">
              <input
                type="number"
                v-model="min_contract_length"
                placeholder=" "
                :min="0"
                class="formControl"
              />
              <label class="fieldFloating__label">Min Contract Length</label>
            </div>

            <div class="search__field fieldFloating border-add" v-if="min_contract_length && min_contract_length > 0" style="margin-top: 5%; background-color:white; padding-right: 15px;">
              <div style="padding-top: 15px;">
                <label style="padding: 5px 20px; color: #28485e; font-weight: 700; font-size: 14px;">Contract type</label>
              </div>
              <select class="formControl" v-model="min_contract_length_type" style="margin-top: -15px;">
                <option value="months">Months</option>
                <option value="years">Years</option>
              </select>
            </div>
            <!-- Building Class -->

            <div
              class="col-md-12 search__field fieldFloating border-add"
              style="
                padding: 0px;
                margin-bottom: 17px;
                margin-top: 20px;
                background-color: white;
              "
            >
              <div
                class="pb-2"
                style="
                  padding-top: 10px;
                  padding-left: 20px;
                  font-weight: bold;
                  color: #28485e;
                  font-size: 14px;
                "
              >
                Building Class
              </div>
              <div class="custom-width" style="width: 89%">
                <multiselect
                  label="name"
                  track-by="id"
                  :multiple="true"
                  v-model="selectedBuildingClass"
                  :options="BuildingClassOptions"
                  class="formControl validate_number"
                ></multiselect>
              </div>
            </div>

            <!-- Floor Size -->
            <div
              v-click-outside="hideFloorSlider"
              class="row"
              style="margin: 0px -8px 0 -12px"
            >
              <div class="col-md-12">
                <!-- Max Capacity -->
                <div class="search__field fieldFloating border-add">
                  <input
                    type="text"
                    min="0"
                    placeholder=" "
                    :value="floorCount"
                    readonly="true"
                    class="formControl validate_number"
                    @click="isFloorSlider = true"
                  />
                  <label class="fieldFloating__label">Typical Floor Size(in Sq ft)</label>
                </div>
              </div>
            </div>
            <div class="search__field fieldFloatingFloor minmaxslider border-add">
              <vue-slider
                v-if="isFloorSlider"
                v-model="floorCount"
                v-bind="options"
                :max="1000"
                :min="0"
                class="formControl"
              ></vue-slider>
            </div>
          </div>
        </div>
    
        <center >
          <button class="clear-filter" @click="clearFiltersHandler()">Clear Filters</button>
          <button class="submit-refine" @click="refineHandler()">Submit</button>
        </center>
      </div>
    </div>

    <div class="loader" v-if="loading"></div>
    <main class="content" v-show="!loading">
      <div class="container-fluid">
        <!-- <div class="search__fields search__fields--innerPage contact_search_cus">
            <div class="row">
              <div class="col-xl-4 col-md-6">
                <div class="fieldFloating">
                  <autocomplete                                                                                                                                            
                    :location-data="location"
                    @getLocation="getLocation"
                    :isFromLanding="false"
                    :isReadonly="false"
                    fromFront="1"
                  />
                </div>
              </div>
              <div class="col-md-6 col-xl-4" v-click-outside="hideSlider">
                <div class="row">
                  <div class="col-md-12">
                    <div class="search__field fieldFloating">
                      <input
                        type="text"
                        max="500"
                        min="1"
                        placeholder=" "
                        :value="headCount"
                        readonly="true"
                        class="formControl validate_number"
                        @click="isSlider = true"
                      />
  
                      <label class="fieldFloating__label">Max Capacity</label>
                    </div>
                  </div>
  
                </div>
                <div class="search__field fieldFloating minmaxslider">
               
                  <vue-slider
                    v-if="isSlider"
                    v-model="headCount"
                    v-bind="options"
                    :max="500"
                    :min="0"
                    class="formControl"
                  ></vue-slider>
            
                </div>
              </div>
              <div class="col-xl-4 col-md-12">
                <div class="fieldFloating">
                  <div class="d-flex">
                    <datepicker
                      ref="picker"
                      @focusout.native="close"
                      class="formControl"
                      v-model="start_date"
                      @focusin.native="getStartDate"
                      :disabledDates="disabledDates"
                    >
                    </datepicker>
  
                    <button v-if="start_date" class="date-reset" @click="removeDate()">
                      X
                    </button>
                  </div>
                  <label
                    :class="
                      start_date
                        ? 'fieldFloating__label date_selected'
                        : 'fieldFloating__label date_open'
                    "
                    >Start Date</label
                  >
                </div>
              </div>
            
            </div>
          </div> -->
        <!-- <button class="refine-btn">Refine</button> -->
        <div class="refine-new-container">
          <button
            class="refine-new"
            @click="toggleRefinePopUp(true)"
            v-if="location && location.trim() != ''"
          >
            Filter
          </button>
        </div>
        <div class="row result" v-if="searchData">
          <div class="result__colLeft col-xl-6 order-1 order-xl-0 maplisting_wrapper">
            <div
              class="result__grid row"
              v-if="getPaginatedProperties(currentPage).length > 0"
            >
              <div
                v-for="(item, index) in getPaginatedProperties(currentPage)"
                :key="item.id"
                class="col-md-6 clickCursor"
                @click.prevent="getDetail(item.id)"
              >
                <a :href="rightClickLink(item.id)">
                  <div class="result__item">
                    <div class="result__thumb">
                      <img :src="`${baseAPI}${item.thumbnail_image}`" alt="Property" />
                    </div>
                    <div class="result__content">
                      <h3 class="">
                        <div class="property-title">
                          {{
                            item.title
                              ? item.title.split(" ").slice(0, 6).join(" ") +
                                (item.title.split(" ").length > 6 ? "... " : "")
                              : "N/A"
                          }}
                          <div class="plan_paid">{{ item.location }}</div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div
                            v-if="item.capacity && item.capacity > 0"
                            class="max-headcount"
                          >
                            Occupancy Limit
                            <div class="noofmaxheadcount">
                              <i class="fas fa-users"></i>
                              {{ item.capacity }}
                            </div>
                          </div>
                          <div
                            :class="
                              item.capacity && item.capacity > 0
                                ? 'textRight t-right'
                                : 't-right'
                            "
                          >
                            <div class="max-headcount">Min Contract Length</div>
                            <!-- <div class="layout mb0" v-if="item.max_length === null">
                              0 - {{ item.min_length }}
                              {{
                                item?.contract_type &&
                                item?.contract_type != "" &&
                                item?.contract_type != "null"
                                  ? item?.contract_type
                                  : "years"
                              }}
                            </div> -->
                            <div class="layout mb0">
                              {{ item.min_length }}
                              {{
                                item?.contract_type &&
                                item?.contract_type != "" &&
                                item?.contract_type != "null"
                                  ? item?.contract_type
                                  : "months"
                              }}
                            </div>
                          </div>
                        </div>
                      </h3>
                      <div class="d-flex justify-content-between price_date">
                        <div class="">
                          <div class="">
                            <div class="exactprice">
                              ${{ item.min_price }} - ${{ item.max_price }}
                            </div>
                          </div>
                          <div class="plan_paid">monthly/person</div>
                        </div>
                        <div class="textRight t-right">
                          <div class="contracts" v-if="available(item.available_from)">
                            <p class="mb0 Boldtext">Available now</p>
                          </div>
                          <div class="contracts" v-else>
                            <p class="mb0">Available from</p>
                            <p class="mb0 plan_paid">
                              {{ item.available_from | getFormatDate }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="result__grid row" v-else>
              <img src="img/no-data-on-page.svg" />
              <div class="no-data-on-page-img">
                <h3>No properties on this page. please navigate to different page.</h3>
              </div>
            </div>
          </div>
          <div class="result__colRight col-xl-6 map_wrapper order-2">
            <div class="map">
              <google-map
                v-if="getPaginatedProperties(currentPage).length > 0"
                :key="currentPage"
                :markers="getPaginatedProperties(currentPage)"
                styleData="width:100%;  height: 100vh;"
              ></google-map>
              <google-map-only
                v-else
                styleData="width:100%;  height: 100vh;"
                :defaultCenter="defaultCenter"
              ></google-map-only>
            </div>
          </div>
        </div>

        <div class="">
          <div class="row">
            <div class="col-md-6">
              <div
                class="pagination"
                id="pagination"
                v-if="chunkProperties.flat().length"
              >
                <a @click="previousPage">&laquo;</a>

                <a v-for="page in totalPages" :key="page" @click="setCurrentPage(page)">{{
                  page
                }}</a>

                <!-- <a @click="setCurrentPage(1)" >1</a>
                <a @click="setCurrentPage(2)" >2</a>
                <a @click="setCurrentPage(3)" >3</a>
                <a @click="setCurrentPage(4)" >4</a>
                <a @click="setCurrentPage(5)" >5</a>
                <a @click="setCurrentPage(6)" >6</a>
                <a @click="setCurrentPage(7)" >7</a>
                <a @click="setCurrentPage(8)" >8</a>
                <a @click="setCurrentPage(9)" >9</a>
                <a @click="setCurrentPage(10)" >10</a> -->
                <!-- <a v-if="totalPages > 1 && currentPage != totalPages" @click="nextPage" >&raquo;</a> -->
                <a @click="nextPage">&raquo;</a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="get-in-touch-box big-screen">
                <h2 class="dark-blue-color">Get In Touch</h2>
                <p class="dark-blue-color">Contact For More Information</p>

                <div class="contact-details">
                  <div class="row">
                    <div class="col">
                      <div class="contact-item">
                        <i class="fas fa-phone-alt call-icon"></i>
                        <div class="grid">
                          <small class="dark-blue-color bold">Call</small>
                          <a href="tel:+1 4252439447">
                            <small class="dark-blue-color">+1 (425) 243-9447</small>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="contact-item">
                        <i class="fas fa-home-alt"></i>
                        <div class="grid">
                          <small class="dark-blue-color bold">Address</small>
                          <small class="dark-blue-color"
                            >3600 136th Pl SE # 300, Bellevue, WA 98006</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="contact-item">
                        <i class="fas fa-envelope"></i>
                        <div class="grid">
                          <small class="dark-blue-color bold">Mail</small>

                          <a href="mailto:contact@extraslice.com"
                            ><small class="dark-blue-color"
                              >contact@extraslice.com</small
                            ></a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="contact-item">
                        <i class="fas fa-globe"></i>
                        <div class="grid">
                          <small class="dark-blue-color bold">Web Address</small>
                          <a href="https://www.extraslice.com" target="_blank">
                            <small class="dark-blue-color">www.extraslice.com</small></a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="get-in-touch-box small-screen">
          <h2 class="dark-blue-color">Get In Touch</h2>
          <p class="dark-blue-color">Contact For More Information</p>

          <div class="contact-details">
            <div class="row">
              <div class="col">
                <div class="contact-item">
                  <i class="fas fa-phone-alt call-icon"></i>
                  <div class="grid">
                    <small class="dark-blue-color bold">Call</small>
                    <a href="tel:+1 4252439447">
                      <small class="dark-blue-color">+1 (425) 243-9447</small>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="contact-item">
                  <i class="fas fa-home-alt"></i>
                  <div class="grid">
                    <small class="dark-blue-color bold">Address</small>
                    <small class="dark-blue-color"
                      >3600 136th Pl SE # 300, Bellevue, WA 98006</small
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="contact-item">
                  <i class="fas fa-envelope"></i>
                  <div class="grid">
                    <small class="dark-blue-color bold">Mail</small>

                    <a href="mailto:contact@extraslice.com"
                      ><small class="dark-blue-color">contact@extraslice.com</small></a
                    >
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="contact-item">
                  <i class="fas fa-globe"></i>
                  <div class="grid">
                    <small class="dark-blue-color bold">Web Address</small>
                    <a href="https://www.extraslice.com" target="_blank">
                      <small class="dark-blue-color">www.extraslice.com</small></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="note" v-if="!searchData && !loading">
          <h2 class="note__text">
            <!-- We currently don't have any spaces that fit the criteria you are looking for. -->
            <!-- No matches yet. Try changing some of your filters or search area, or <a href="https://extraslice.com/contact-us/" target="
              _blank">contact our team</a>  for help finding your perfect space. -->
            No matches found. Try adjusting your filters or
            <a href="https://extraslice.com/contact-us/" target="_blank"
              >contact our team
            </a>
            for assistance in finding your perfect space.
          </h2>
          <!-- <h3 class="note__help">Get alerted when we find a match.</h3> -->
        </div>
        <div class="alertFilter" v-if="!searchData && !loading">
          <!-- <div class="alertFilter__item" @click="getSearchByLocation()">
              <i class="fas fa-map-marker-alt"></i>
              <div>
                9 listings in Mercer Island, WA
                <span class="colorGray--darker">(7 miles from Seattle)</span>
              </div>
            </div>-->
          <div class="alertFilter__item" @click="getSearchByDate()" v-if="getDateRange()">
            <div>
              <div v-if="this.start_date">
                <i class="fas fa-calendar-alt"></i>
                {{ dateProperties }} listings available in
                {{ getDateRange() }}
              </div>
              <div v-else>
                <i class="fas fa-map-marker-alt"></i>
                No property available in your searched location
              </div>
            </div>
          </div>
          <div class="alertFilter__item" @click="getSearchByCapacity()">
            <i class="fas fa-user-alt"></i>
            <div>
              {{ capacityProperties }} listings that accomodate {{ headCount }} people
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer-component></footer-component>
  </div>
</template>

<script>
import HeaderComponent from "@/containers/client/Header";
import FooterComponent from "@/containers/client/Footer";
import { getPropertyBySearch } from "@/api/properties";
import { getContractLengths } from "@/api/contractLengths";
import Datepicker from "vuejs-datepicker";
import autocomplete from "./AutoComplete";
import GoogleMap from "./GoogleMap";
import GoogleMapOnly from "./GoogleMapOnly.vue";
import VueSlider from "vue-slider-component";
import { getToken } from "@/utils/auth";
import moment from "moment";

import VueSimpleRangeSlider from "vue-simple-range-slider";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";

import VueCryptojs from "vue-cryptojs";

export default {
  name: "ListProperties",
  components: {
    HeaderComponent,
    Datepicker,
    autocomplete,
    VueSlider,
    GoogleMap,
    GoogleMapOnly,
    VueSimpleRangeSlider,
    FooterComponent,
  },
  data() {
    const min = this.$router.history.current.query.min_capacity
      ? this.$router.history.current.query.min_capacity
      : null;
    const max = this.$router.history.current.query.max_capacity
      ? this.$router.history.current.query.max_capacity
      : null;
    // const headCount = this.$router.history.current.query.headCount
    //   ? this.$router.history.current.query.headCount
    //   : 1;
    const headCount = 1;
    return {
      currentPage: 1,
      itemsPerPage: 10,
      baseAPI: process.env.VUE_APP_BASE_API + process.env.VUE_APP_IMAGE_PATH,
      isFloorSlider: false,
      floorCount: 0,
      isSlider: false,
      headCount,
      selectedBuildingClass: [],
      BuildingClassOptions: [
        { id: "A", name: "Class A" },
        { id: "B", name: "Class B" },
        { id: "C", name: "Class C" },
      ],
      isMaxSlider: false,
      min,
      max,
      range: [min, max],
      isContractLength: false,
      isLayoutDesign: false,
      contractLength: null,
      searchData: false,
      contractLengths: [],
      layoutDesign: null,
      markers: [],
      places: [],
      currentPlace: null,
      properties: [],
      chunkProperties: [],
      perPage: "",
      prev: "",
      next: "",
      last: "",
      totalData: "",
      totalPage: "",
      current_page: 1,
      map: null,
      infoContent: "",
      loading: true,
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      options: {
        dotSize: 14,
        width: "auto",
        height: 4,
        contained: false,
        direction: "ltr",
        data: null,
        min: 0,
        max: 100,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: "focus",
        tooltipPlacement: "top",
        tooltipFormatter: void 0,
        useKeyboard: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        marks: false,
        dotOptions: void 0,
        process: true,
        dotStyle: void 0,
        railStyle: void 0,
        processStyle: void 0,
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
      capacityProperties: 0,
      dateProperties: 0,
      dateRange: null,
      capacityRange: null,
      start_date: "",
      min_contract_length: 0,
      min_contract_length_type: 'months',
      location: this.$store.state.location.currentLocation
        ? this.$store.state.location.currentLocation
        : "Bellevue, WA, US",
      capacity: null,
      min_capacity: 1,
      max_capacity: 500,
      defaultCenter: { lat: 52.51195, lng: 6.089625 },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      showRefinePopup: false,
    };
  },
  watch: {
    currentLocation(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleLocationChange(newValue);
        this.currentPage = 1;
      }
    },
  },
  filters: {
    getLength(data) {
      if (data == 12) {
        return "1 year";
      }
      if (data > 11) {
        return (
          Math.floor(data / 12) +
          " years " +
          (data % 12 > 0 ? (data % 12) + " months" : "")
        );
      } else return data + " months";
    },
    getFormatDate(data) {
      let startDate = data.split("-");
      return startDate[2] + "/" + startDate[1] + "/" + startDate[0];
    },
  },
  created() {
    getContractLengths(1, "all").then((res) => {
      this.contractLengths = res.data.data;
    });
    let searchData = {
      location: this.location,
      min_capacity: this.headCount,
      max_capacity: this.max_capacity,
      start_date: this.start_date,
      min_contract_length:
        this.min_contract_length && this.min_contract_length != 0
          ? this.min_contract_length
          : null,
      building_class:
        this.selectedBuildingClass && this.selectedBuildingClass.length > 0
          ? this.selectedBuildingClass.map((val) => val.id)
          : null,
      floor_size: this.floorCount,
      min_contract_length_type: this.min_contract_length_type || 'months'
    };
    if (!this.location && !this.capacity && !this.start_date) {
      this.$notify({
        group: "notify",
        type: "warning",
        text: "please add appropriate search data",
      });
      this.$router.push("/");
    }
    this.searchData = false;
    this.loading = true;
    this.getPropertyBySearchCriteria(searchData);
  },
  mounted() {
    var testarray = document.getElementsByClassName("vdp-datepicker");
    if (this.start_date) {
      testarray[0].classList.add("is-open-date");
    } else {
      testarray[0].classList.remove("is-open-date");
    }

    // let searchData = {
    //   location: this.location,
    //   min_capacity: 1,
    //   max_capacity: 500,
    //   start_date: this.start_date,
    // };
    // console.log("searchData", searchData)
    // this.getPropertyBySearchCriteria(searchData);
  },
  computed: {
    flattenedProperties() {
      return this.chunkProperties.flat();
    },
    currentLocation() {
      return this.$store.state.location.currentLocation;
    },
    totalPages() {
      return Math.ceil(this.chunkProperties.flat().length / this.itemsPerPage);
    },
  },
  methods: {
    setCurrentPage(val) {
      this.currentPage = val;
    },
    getPaginatedProperties(page) {
      const startIndex = (page - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.chunkProperties.flat().slice(startIndex, endIndex);
    },
    nextPage() {
      if (this.currentPage * this.itemsPerPage < this.chunkProperties.flat().length) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    toggleRefinePopUp(show) {
      this.showRefinePopup = show;
      // console.log("show", show);
    },
    closeRefinePopup() {
      this.showRefinePopup = false;
    },
    handleLocationChange(location) {
      this.location = location;
      // Perform actions based on the new location
      // console.log(`Location has changed to: ${location}`);
      let searchData = {
        location: location,
        min_capacity: this.headCount || 1,
        max_capacity: this.max_capacity || 500,
        start_date: this.start_date,
        min_contract_length:
          this.min_contract_length && this.min_contract_length != 0
            ? this.min_contract_length
            : null,
        building_class:
          this.selectedBuildingClass && this.selectedBuildingClass.length > 0
            ? this.selectedBuildingClass.map((val) => val.id)
            : null,
        floor_size: this.floorCount,
        min_contract_length_type: this.min_contract_length_type || 'months'
      };
      // console.log(`searchData has changed to: ${searchData}`);

      this.getPropertyBySearchCriteria(searchData);

      // Add more logic here if necessary
    },
    clearFiltersHandler(){
      // this.showRefinePopup = false;
      this.headCount = 1,
      this.start_date = "",
      this.min_contract_length = 0,
      this.selectedBuildingClass = [],
      this.floorCount = 0,
      this.min_contract_length_type = 'months'
      this.refineHandler();
    },
    refineHandler() {
      console.log("BUILDINGS------>>>>", this.selectedBuildingClass);
      let searchData = {
        location: this.location,
        min_capacity: this.headCount || 1,
        max_capacity: this.max_capacity || 500,
        start_date: this.start_date,
        min_contract_length:
          this.min_contract_length && this.min_contract_length != 0
            ? this.min_contract_length
            : null,
        building_class:
          this.selectedBuildingClass && this.selectedBuildingClass.length > 0
            ? this.selectedBuildingClass.map((val) => val.id)
            : null,
        floor_size: this.floorCount,
        min_contract_length_type: this.min_contract_length_type || 'months'

      };

      this.getPropertyBySearchCriteria(searchData);
      this.closeRefinePopup();
    },
    rightClickLink(id) {
      return "/detail/" + btoa(id);
    },
    getTotalMonthlyCost(oldPrice, layouts, contracts) {
      if (oldPrice) {
        return oldPrice.toFixed(2);
      }
      let defaultLayout =
        layouts.filter((x) => x.is_default).length > 0
          ? layouts.filter((x) => x.is_default)[0]
          : layouts[0];

      let defaultContract =
        contracts.filter((x) => x.is_default).length > 0
          ? contracts.filter((x) => x.is_default)[0]
          : contracts[0];
      return (
        (defaultLayout.price * defaultContract.percent) /
        100 /
        defaultLayout.capacity
      ).toFixed(2);
    },
    getHeadCount(oldCount, layouts) {
      if (oldCount) {
        return oldCount;
      }
      if (layouts.filter((x) => x.is_default).length > 0) {
        return layouts.filter((x) => x.is_default)[0].capacity;
      } else {
        return this.getMaxHeadCount(layouts.filter((x) => !x.is_default));
      }
      // let defaultLayout =
      //   layouts.filter((x) => x.is_default).length > 0
      //     ? layouts.filter((x) => x.is_default)[0]
      //     : this.getMaxHeadCount(layouts.filter((x) => !x.is_default));
      // let defaultLayout =
      //   layouts.filter((x) => x.is_default).length > 0
      //     ? layouts.filter((x) => x.is_default)[0]
      //     : layouts[0];
      // return defaultLayout.capacity;
    },
    getMaxHeadCount(layouts) {
      let capacities = [];
      layouts.forEach((layout) => {
        capacities.push(layout.capacity);
      });
      return Math.max(...capacities);
    },
    getContract(contracts) {
      // console.log(contracts);
      let contra = contracts.filter((x) => x.is_default);
      if (contra.length > 0) {
        return contra[0].contract_length?.length;
      } else {
        // console.log(contracts[0]);
        return contracts[0].contract_length?.length;
      }
    },
    removeDate() {
      this.start_date = "";
    },
    removeClasss() {
      var body = document.body;
      body.classList.remove("is-modalOpen");
    },
    getDateRange() {
      let date = new Date(this.start_date);
      let year = date.getFullYear();
      let date1 = date.getDate();
      let month = date.getMonth();
      if (month < 0 || !year) {
        return false;
      }
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthNames[month] + " " + year;
    },
    hideFloorSlider() {
      this.isFloorSlider = false;
    },
    hideSlider() {
      this.isSlider = false;
      this.isMaxSlider = false;
    },
    hideContractLength() {
      this.isContractLength = false;
    },
    getLocation(location, isSearchChange = false) {
      this.location = location;
      if (isSearchChange == true) {
        // this.$route.query.location = this.location;
        // let count = parseInt(this.headCount, 10);
        // let min_capacity =
        //   count - (count * 20) / 100 - 5 < 0
        //     ? 0
        //     : parseInt(count - (count * 20) / 100 - 5, 10);
        // let max_capacity = parseInt(count + (count * 20) / 100 + 5, 10);
        this.$router.push({
          path: "list",
          query: {
            location: this.location,
            // capacity: this.capacity,
            min_capacity: this.headCount,
            max_capacity: this.max_capacity,
            start_date: this.start_date,
            headCount: this.headCount,
            min_contract_length:
              this.min_contract_length && this.min_contract_length != 0
                ? this.min_contract_length
                : null,
            building_class:
              this.selectedBuildingClass && this.selectedBuildingClass.length > 0
                ? this.selectedBuildingClass.map((val) => val.id)
                : null,
            floor_size: this.floorCount,
            min_contract_length_type: this.min_contract_length_type || 'months'

          },
        });
        let searchData = {
          location: this.location,
          // capacity: this.capacity,
          min_capacity: this.headCount,
          max_capacity: this.max_capacity,
          start_date: this.start_date,
          min_contract_length:
            this.min_contract_length && this.min_contract_length != 0
              ? this.min_contract_length
              : null,
          building_class:
            this.selectedBuildingClass && this.selectedBuildingClass.length > 0
              ? this.selectedBuildingClass.map((val) => val.id)
              : null,
          floor_size: this.floorCount,
          min_contract_length_type: this.min_contract_length_type || 'months'

        };
        this.getPropertyBySearchCriteria(searchData);
      }
    },
    getNearBy() {
      var elementName = document.getElementsByClassName("result__filter");
      elementName[0].classList.toggle("is-filterOpen");
    },
    getStartDate() {
      var testarray = document.getElementsByClassName("vdp-datepicker");
      if (!this.$refs.picker.isOpen) {
        testarray[0].classList.add("is-open-date");
      } else {
        testarray[0].classList.remove("is-open-date");
      }
    },
    close() {
      var testarray = document.getElementsByClassName("vdp-datepicker");
      if (!this.$refs.picker.isOpen && !this.start_date) {
        testarray[0].classList.remove("is-open-date");
      } else {
        testarray[0].classList.add("is-open-date");
      }
    },
    getSearchByLocation() {
      this.location = "Mercer Island, WA";
      this.getLocation("Mercer Island, WA");
    },
    getSearchByDate() {
      // this.searchData = true;
      this.start_date = this.dateRange;
    },
    getSearchByCapacity() {
      this.capacity = this.capacityRange;
    },
    isSameDay(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    },

    async getPropertyBySearchCriteria(searchData) {
      // console.log("getPropertyBySearchCriteria---searchData", searchData);
      searchData.capacity = null; //adding this as dummy data as api is throwing error we need to remove that when api fixes
      searchData.min_capacity =
        searchData.min_capacity === "NaN" ? null : searchData.min_capacity;
      searchData.max_capacity =
        searchData.max_capacity === "NaN" ? null : searchData.max_capacity;
      if (searchData.min_capacity && searchData.max_capacity) {
        await getPropertyBySearch(searchData)
          .then((res) => {
            this.properties = (res.data && res.data.data) || [];

            this.loading = false;

            this.chunkProperties = [];

            // console.log(Array.isArray(this.properties));

            if (this.properties.length && this.properties.length > 0) {
              this.searchData = true;
              this.loading = false;
              var index = 0;
              let chunk_size = 2;
              var arrayLength = this.properties.length;
              let myChunk = [];
              for (index = 0; index < arrayLength; index += chunk_size) {
                myChunk = this.properties.slice(index, index + chunk_size);
                // Do something if you want with the group
                if (
                  myChunk.filter(
                    (val) =>
                      val &&
                      new Date() <
                        new Date(val.available_end).setDate(
                          new Date(val.available_end).getDate() + 1
                        ) || val && !val.available_end
                  ).length > 0
                ) {
                  this.chunkProperties.push(
                    myChunk.filter(
                      (val) =>
                        val &&
                        new Date() <
                          new Date(val.available_end).setDate(
                            new Date(val.available_end).getDate() + 1
                          ) || val && !val.available_end
                    )
                  );
                }
                // console.log("chunkproperties.....", this.chunkProperties);
              }
            } else {
              this.searchData = false;
              let resp = res.data.data;
              this.dateRange = resp.dateRange;
              this.dateProperties = resp.dateProperties;
              this.capacityProperties = resp.capacityProperties;
              this.capacityRange = resp.capacityRange;
            }
            this.capacity = this.capacity;
          })
          .catch((error) => {
            console.error(error);
          });
        this.loading = false;
      }
    },
    setLength(contLength) {
      this.contractLength = contLength;
      this.isContractLength = false;
    },
    setLayout(layout) {
      this.layoutDesign = layout;
      this.isLayoutDesign = false;
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    available(date) {
      let today_date = new Date();
      let new_date = new Date(date);
      if (new_date > today_date) {
        return false;
      }
      return true;
    },
    getDetail(id) {
      id = btoa(id);
      this.$router.push("/detail/" + id);
    },
  },
};
</script>

<style scoped lang="scss">
.clickCursor {
  cursor: pointer;
}

.test .vdp-datepicker__calendar {
  width: 260px !important;
}

.simple-range-slider {
  width: 100% !important;
  background-color: white;
  padding: 0;
  margin: 0.25em;
}
.noofmaxheadcount,
.date {
  font-size: 1.4rem;
}
.layout,
.plan_paid,
.noofmaxheadcount,
.date {
  color: dimgrey;
}
.exactprice,
.contracts,
.max-headcount,
.noofmaxheadcount,
.date {
  font-weight: 500 !important;
}
.space-btwn {
  justify-content: space-between;
}
.result__content .price {
  font-family: Inter-regular;
}

.result__content > h3 {
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}
@media (max-width: 1599px) {
  .price_date {
    flex-wrap: wrap;
    > div:nth-child(3) {
      margin-top: 12px;
      width: 100%;
    }
  }
}
.small-screen {
  display: none;
  margin: 20px 0;
}

.big-screen {
  display: block;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .small-screen {
    display: block;
    margin: 20px 0;
  }

  .big-screen {
    display: none;
    margin: 0 auto;
  }
}
// @media (max-width: 1450px) {
.map_wrapper .map {
  min-height: 100vh !important;
}
.map_wrapper {
  height: 400px;
}

.call-icon {
  transform: rotate(90deg);
}

// .maplisting_wrapper {
//   height: 500px;
//   overflow-y: auto;
// }
// }
.map {
  min-height: 670px;
  margin-bottom: 20px;
}
// .maplisting_wrapper {
//   height: 670px;
//   overflow-y: auto;
// }
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #268cab;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #268cab;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.grid {
  display: grid;
}
.bold {
  font-weight: bold;
}
.dark-blue-color {
  color: #284858;
}

.get-in-touch-box {
  padding: 20px;
  background-color: #d8d8d8;
  border-radius: 8px;
}

.get-in-touch-box h2 {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.get-in-touch-box p {
  font-size: 1.2em;
  margin-bottom: 1.5em;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.row {
  display: flex;
  justify-content: space-between;
}

.col {
  flex: 1;
  display: flex;
  justify-content: start;
}

.contact-item {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5em;
}

.contact-item i {
  font-size: 1.5em;
  color: #284858;
}

.contact-item span {
  font-size: 1.1em;
}
.refine-btn {
  background-color: #268cab;
  padding: 10px 50px;
  border-radius: 100px;
  color: white;
  border: navajowhite;
  box-shadow: 1px 1px 5px #b4b4b4;
  margin-bottom: 16px;
}

.refine-new {
  padding: 10px 45px;
  background-color: #268cab;
  border-radius: 100px;
  color: white;
  border: none;
  margin-bottom: 12px;
  margin-right: 17px;
  margin-top: -18px;
}
.refine-new-container {
  display: flex;
  justify-content: flex-end;
}
</style>
<style lang="scss">
// Import Main styles for this application
@import "../../assets/scss/client";
</style>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.popup-title {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
  text-align: center;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5em;
  color: #888;
  cursor: pointer;
}

.popup-close:hover {
  color: #333;
}

.popup-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-submit,
.form-close {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  color: white;
}

.form-submit {
  background-color: #007bff;
  margin-bottom: 10px;
}

.form-submit:hover {
  background-color: #0056b3;
}

.form-close {
  background-color: #ccc;
}

.form-close:hover {
  background-color: #999;
}
.border-add {
  /* border: 1px solid black; */
}
.popup-content {
  background-color: #f2f2f2;
}
.fieldFloating .formControl[data-v-d556e1e6] {
  height: 59px !important;
  padding: 20px 31px 5px 20px;
}
.submit-refine {
  padding: 10px 50px;
  background-color: #268cab;
  border-radius: 7px;
  color: white;
  border: none;
  /* margin-top: 20px; */
}
.submit-refine:hover{
  cursor: pointer;
}
.clear-filter {
  padding: 10px 35px;
  background-color: #de0202;
  border-radius: 7px;
  color: white;
  border: none;
  /* margin-top: 20px; */
  margin-right: 10px;
}
.clear-filter:hover{
  cursor: pointer;
}
.vdp-datepicker input[type="text"] {
  background-color: transparent !important;
}
.popup-title[data-v-d556e1e6] {
  font-size: 19px !important;
}
.popup-content {
  background-color: #f2f2f2;
}
.fieldFloating .fieldFloating .formControl {
  height: 70px !important;
  padding: 20px 31px 5px 20px;
}
.submit-refine {
  padding: 10px 50px;
  background-color: #268cab;
  border-radius: 7px;
  color: white;
  border: none;
  margin-top: 20px;
}
.vdp-datepicker input[type="text"] {
  background-color: transparent !important;
}

.popup-title {
  margin-bottom: 20px;
  font-size: 17px;
  color: #333;
  text-align: center;
}
.maplisting_wrapper {
  scrollbar-width: none !important; /* For Firefox */
  -ms-overflow-style: none !important; /* For Internet Explorer and Edge */
}

.maplisting_wrapper::-webkit-scrollbar {
  display: none !important; /* For Chrome, Safari, and Opera */
}
.no-data-on-page-img {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pagination {
  display: inline-block;
  margin-top: 20px;
  float: right;
}
.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  margin: 0 4px;
  cursor: pointer;
}
.pagination a.active {
  background-color: #268cab;
  color: white;
  border: 1px solid #268cab;
}
.pagination a:hover:not(.active) {
  background-color: #ddd;
}
.content {
  margin-top: 20px;
}
.get-in-touch-box {
  border-radius: 0px !important;
}
.fieldFloating {
  box-shadow: 0px 0px 21px #dcd7d7 !important;
  border-radius: 8px;
}
@media screen and (min-width: 320px) and (max-width: 520px) {
  .custom-width {
    width: 88% !important;
  }
}
.multiselect__content-wrapper{
  position: static !important; 
}
.multiselect__tags{
  border: none !important;
}
</style>
