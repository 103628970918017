<template>
  <div>
    <gmap-map ref="gmap" :center="center" :zoom="10" :style="styleData"> </gmap-map>
  </div>
</template>

<script>
export default {
  name: "GoogleMapOnly",
  props: {
    styleData: {
      type: String,
      default: "width:100%;  height: 100vh;",
    },
    defaultCenter: {
      type: Object,
      default: () => ({ lat: 52.51195, lng: 6.089625 }),
    },
  },
  data() {
    return {
      center: this.defaultCenter,
    };
  },
};
</script>
